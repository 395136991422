export enum ERouteUrl {
  quiz = "/quiz",
  tournament = "/tournament",
  team = "/team",
  squad = "/squad",
  //static
  academy = "/academy",
  terms = "/terms",
  contact = "/contact",
  privacy = "/privacy",
  //article
  bonuses = "/bonuses",
  bonus = "/bonus",
  blog = "/blog",
  news = "/news",
  glossary = "/glossary",
  //tips
  tips = "/tips",
  tip = "/tip",
  football = "/tips/football",
  basketball = "/tips/basketball",
  mma = "/tips/mma",
  hockey = "/tips/hockey",
  cybersport = "/tips/cybersport",
  //user
  parsonal = "/account",
  password = "/account" + "/password",
  notifications = "/account" + "/notifications",
  bookmakers = "/account" + "/bookmakers",
  account = "/account",
  login = "/login",
  signup = "/signup",
  user = "/user",
  passwordReset = "/password-reset",
  passwordSet = "/recover_password",
  comments = "/comments",
  //bk
  bk = "/bk",
  bk_mx = "/casas-de-apuestas",
  review = "/reviews",
  pps = "/pps",
  //casino
  casino = "/casino",
  //rating
  rating = "/ratings",

  betboom_quiz = "/betboom-quiz",
  search = "/search",
}

export const getPublicImgPath = (iconPath?: string) => "/img/" + iconPath
