import NoSsr from "@material-ui/core/NoSsr"
import clsx from "clsx"
import React, { FC, useRef } from "react"
import { Link, NavLink, NavLinkProps } from "react-router-dom"
import { useDi } from "../App/di"
import { Button } from "../common/button"
import { Img } from "../common/img"
import { Typography } from "../common/typography"
import {
  TBlogInHeader,
  TBonusInHeader,
  TLayout,
  TLayoutBk,
  TNavigation_item,
  TTipInHeader,
} from "../payload"
import { ERouteUrl } from "../routes/routes-config"
import { Icon } from "../slider/icons"
import { burgerMenuWidth, headerShowFirstItems, navId } from "./header-config"

export type THeaderNavCs =
  | "root"
  | "ul"
  | "menu"
  | "menuOpen"
  | "overlay"
  | "header"
  | "header_es"
  | "header_ru"
  | "firstLvl"
  | "firstLvlHide"
  | "more"
  | "login"
  | "user"
  | "avatar"
  | "footer"
  | "footer_login"
  | "arrow_icon"
  | "drawer"

type TAppNav = {
  className?: string
  burgerMenuIsOpen: boolean
  closeBurgerMenu: () => void
  layout: TLayout
}

export const HeaderNav: FC<TAppNav> = ({
  className,
  burgerMenuIsOpen,
  closeBurgerMenu,
  layout,
}) => {
  const {
    store,
    cs: { HeaderNav: c },
    lang,
    lng,
  } = useDi()
  const { auth } = store
  const { header_nav, top3Bk } = layout
  return (
    <>
      {burgerMenuIsOpen && (
        <div className={c.overlay} onClick={closeBurgerMenu} />
      )}
      <div className={clsx(c.drawer, c.menu, burgerMenuIsOpen && c.menuOpen)}>
        <nav id={navId} className={clsx(c.root, className)}>
          <div
            className={clsx(c.header, lng === "es" ? c.header_es : c.header_ru)}
            onClick={closeBurgerMenu}
          />
          <ul className={c.ul}>
            {header_nav.map((firstLvl, i) => (
              <NavItem1Lvl
                layout={layout}
                key={firstLvl.url_link}
                nav={firstLvl}
                className={clsx(
                  c.firstLvl,
                  i >= headerShowFirstItems && c.firstLvlHide
                )}
              />
            ))}
            {header_nav.slice(headerShowFirstItems).length > 0 && (
              <NavItem1Lvl
                layout={layout}
                nav={{
                  text_link: lang.header_nav__text_link__more,
                  url_link: "",
                  text_link_additional: null,
                  image_url: null,
                  Children: header_nav.slice(headerShowFirstItems),
                }}
                className={c.more}
              />
            )}
          </ul>
          <NoSsr>
            {/* <div className={clsx(c.footer)}>
              {auth.user ? (
                <Link
                  to={ERouteUrl.user + "/" + auth.user.data.php_id}
                  className={c.user}
                >
                  <CommentsAvatar
                    className={c.avatar}
                    width={36}
                    src={auth.user.data.avatar || undefined}
                    name={auth.user.data.nickname}
                  />

                  <Typography name="btsMediun" maxLines={1}>
                    {auth.user.data.nickname}
                  </Typography>
                </Link>
              ) : (
                <Button
                  className={c.footer_login}
                  variant="tertiary"
                  size="medium"
                  onClick={auth.login}
                >
                  <Icon name="person" width={16} />
                  Войти
                </Button>
              )}
              <Icon
                className={auth.userLoaded ? undefined : c.arrow_icon}
                name={auth.userLoaded ? "exit" : "arrow"}
                width={24}
                onClick={auth.userLoaded ? auth.logout : auth.login}
              />
            </div> */}
          </NoSsr>
        </nav>
      </div>
    </>
  )
}

let defaultZIndex = 1

export type TNavItem1LvlCs =
  | "root"
  | "rootHasChildren"
  | "firstLvl"
  | "secondLvl"
  | "secondLvlItem"
  | "secondLvlLi"
  | "firstLvlHasChildren"
  | "secondLvlContainer"
  | "back"
  | "back_es"
  | "back_ru"
  | "menuEl"

type TNavItem = Partial<NavLinkProps> & {
  nav: TNavigation_item
  layout: TLayout
}
const NavItem1Lvl: FC<TNavItem> = (props) => {
  const {
    cs: { NavItem1Lvl: cs },
    lang,
    lng,
  } = useDi()
  const { nav, className, onClick, layout, ...rest } = props
  const { top3Bk, bonus_in_header, blogs_in_header, tips_in_header } = layout

  const childrenRef = useRef<HTMLDivElement>(null)
  const hasChildren = nav.Children.length > 0

  const _onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!nav.url_link) {
      e.preventDefault()
    }
    onClick?.(e)
  }

  const toggleSecondLvl = (e: React.MouseEvent, open: boolean) => {
    e.stopPropagation()
    const current = childrenRef.current
    const nav = document.getElementById(navId)
    if (!current || !nav) return
    current.style.zIndex = `${++defaultZIndex}`
    setTimeout(() => {
      nav.style.transform = `translateX(${open ? -burgerMenuWidth : 0}px)`
    }, 100)
  }

  return (
    <li
      onClick={(e) => toggleSecondLvl(e, true)}
      className={clsx(
        cs.root,
        cs.menuEl,
        hasChildren && cs.rootHasChildren,
        className
      )}
    >
      <NavLink
        {...rest}
        to={nav.url_link || "/"}
        className={clsx(cs.firstLvl, hasChildren && cs.firstLvlHasChildren)}
        onClick={_onClick}
      >
        {nav.text_link}
      </NavLink>

      {hasChildren && (
        <div className={cs.secondLvlContainer} ref={childrenRef}>
          <div
            className={clsx(cs.back, lng === "es" ? cs.back_es : cs.back_ru)}
            onClick={(e) => toggleSecondLvl(e, false)}
          />
          <ul className={cs.secondLvl}>
            <li className={clsx(cs.secondLvlLi, cs.menuEl)}>
              <NavLink to={nav.url_link} className={cs.secondLvlItem}>
                {nav.text_link_additional || nav.text_link}
              </NavLink>
            </li>
            {nav.Children.map((ch) => (
              <li key={ch.url_link} className={clsx(cs.secondLvlLi, cs.menuEl)}>
                <NavLink to={ch.url_link} className={cs.secondLvlItem}>
                  {ch.text_link}
                </NavLink>
              </li>
            ))}
            {nav.text_link === lang.header_nav__text_link__bookmakers && (
              <li>
                <Bks bks={top3Bk} />
              </li>
            )}
            {nav.text_link === lang.header_nav__text_link__academy && (
              <li>
                <AcademyUl blogs={blogs_in_header} />
              </li>
            )}
            {nav.text_link === lang.header_nav__text_link__bonuses && (
              <Bonus bonus={bonus_in_header} />
            )}
            {nav.text_link === lang.header_nav__text_link__tips && (
              <li>
                <Tips tips={tips_in_header} />
              </li>
            )}
          </ul>
        </div>
      )}
    </li>
  )
}

export type THeaderDataCs =
  | "bkUl"
  | "bkLi"
  | "bkA"
  | "bkLogo"
  | "bkN"
  | "academyUl"
  | "academyLiTest"
  | "academyLiTestEmoji"
  | "academyTestButton"
  | "bonusLink"
  | "bonusImg"
  | "bonusTitle"
  | "bonusIcon"
  | "bonusImgProportion"
  | "blogLink"
  | "blogLinkNoImg"
  | "blogImg"
  | "blogLi"
  | "blogTitle"
  | "tipsUl"
  | "tipsLink"
  | "tipsTitle"
  | "tipsLogo"
  | "tipsLi"

const Bks: FC<{ bks: TLayoutBk[] }> = ({ bks }) => {
  const {
    cs: { HeaderData: cs },
    region,
  } = useDi()
  const path_to_bk = region.name === "mx" ? ERouteUrl.bk_mx : ERouteUrl.bk

  return (
    <ul className={cs.bkUl}>
      {bks.map((bk) => (
        <li className={cs.bkLi} key={bk.slug}>
          <Link className={cs.bkA} to={path_to_bk + "/" + bk.slug}>
            <Img
              className={cs.bkLogo}
              src={bk.logo_url}
              width={72}
              imgAttr={{ width: 72, height: 28 }}
              alt={bk.name}
              itemProp={undefined}
            />
            <Typography className={cs.bkN} name="btsLarge">
              {bk.rating.toFixed(1)}
            </Typography>
          </Link>
        </li>
      ))}
    </ul>
  )
}

const AcademyUl: FC<{ blogs: TBlogInHeader[] }> = ({ blogs }) => {
  const {
    cs: { HeaderData: cs },
    lang,
  } = useDi()
  return (
    <ul className={cs.academyUl}>
      {blogs.map((b) => (
        <li key={b.slug} className={cs.blogLi}>
          <Link
            to={ERouteUrl.blog + "/" + b.slug}
            className={b.image_url ? cs.blogLink : cs.blogLinkNoImg}
          >
            <Typography name="h5" className={cs.blogTitle}>
              {b.abbreviation}
            </Typography>
            {b.image_url && (
              <Img
                src={b.image_url || ""}
                alt={b.image_alt || b.abbreviation}
                width={65}
                className={cs.blogImg}
                imgAttr={{ width: 65, height: 40 }}
                itemProp={undefined}
              />
            )}
          </Link>
        </li>
      ))}
      <li className={cs.academyLiTest}>
        <Typography name="h4">
          {lang.header_nav__academy_test__title}
        </Typography>
        <div className={cs.academyLiTestEmoji}>🤔</div>
        <Button
          size="medium"
          className={cs.academyTestButton}
          to={ERouteUrl.quiz + "/academy"}
        >
          {lang.header_nav__academy_test__button}
        </Button>
      </li>
    </ul>
  )
}

const Bonus: FC<{ bonus: TBonusInHeader | null }> = ({ bonus }) => {
  if (!bonus) return null
  const {
    cs: { HeaderData: cs },
  } = useDi()
  return (
    <li>
      <Link className={cs.bonusLink} to={ERouteUrl.bonus + "/" + bonus.slug}>
        <div className={cs.bonusImgProportion}>
          <Img
            src={bonus.image_url || ""}
            alt={bonus.image_alt || bonus.abbreviation}
            width={240}
            className={cs.bonusImg}
            imgAttr={{ width: 240, height: 200 }}
            itemProp={undefined}
          />
        </div>
        <Typography name="h5" className={cs.bonusTitle}>
          {bonus.abbreviation}
        </Typography>
        <Icon name="gift" width={30} className={cs.bonusIcon} />
      </Link>
    </li>
  )
}

const Tips: FC<{ tips: TTipInHeader[] }> = ({ tips }) => {
  if (tips.length === 0) return null
  const {
    cs: { HeaderData: cs },
    lang,
  } = useDi()
  return (
    <ul className={cs.tipsUl}>
      {tips.map((t) => (
        <li key={t.slug} className={cs.tipsLi}>
          <Link to={ERouteUrl.tip + "/" + t.slug} className={cs.tipsLink}>
            <div className={cs.tipsTitle}>
              {lang.header_nav__tip_card__tip + ` «${t.team1}» – «${t.team2}»`}
            </div>
            <Img
              src={t.logo1}
              className={cs.tipsLogo}
              width={32}
              alt={t.team1}
              imgAttr={{ width: 30, height: 30 }}
              itemProp={undefined}
            />
            <Img
              src={t.logo2}
              className={cs.tipsLogo}
              width={32}
              alt={t.team2}
              imgAttr={{ width: 30, height: 30 }}
              itemProp={undefined}
            />
          </Link>
        </li>
      ))}
    </ul>
  )
}
